@tailwind base;
@tailwind components;
@tailwind utilities;

main {
  background: url("/static/fullbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  // .bgwrapper {
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   width: 100%;
  //   z-index: 1;
  //   background-repeat: no-repeat;
  //   display: block;
  //   height: 100%;
  //   pointer-events: none;

  //   .imageContainer {
  //     height: 100%;
  //     width: 100%;
  //     background: url('/static/fullbg.jpg');
  //     background-repeat: no-repeat;
  //     background-size: cover;
  //     background-position: top center;
  //   }
  // }
}

.loaderWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
}

body.collapsed {
  height: 100%;
  overflow-y: hidden;
}

.poolsMenu {
  background: rgba(231, 19, 131, 0.2) !important;
  border: 1px solid #e71383;
  border-radius: 0 !important;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.headerBar {
  background: #5e0835;
}

.connectBtn {
  background: #460626 !important;
  border-radius: 0 !important;
  border: none !important;
  color: #ccc9dd !important;
}

.overlayWrap {
  background: rgba(35, 3, 19, 0.81);
  border: 1px solid #e71383;
  /* card-blur */

  backdrop-filter: blur(20px);
  border-radius: 0;
}

.collapseBtn {
  background: linear-gradient(88.04deg, #e71383 0%, #e71383 100%) !important;
}

.arrowBtn {
  background: #230313 !important;
  border: 1px solid #5e0835;
  border-radius: 0;
}

// .custom-border {
//   border: 1px solid #263673 !important;
// }

.burgerMenu {
  background: linear-gradient(
      0deg,
      rgba(231, 19, 131, 0.2),
      rgba(231, 19, 131, 0.2)
    ),
    #000000;
}

.navMenuMobile {
  background: linear-gradient(
      0deg,
      rgba(231, 19, 131, 0.2),
      rgba(231, 19, 131, 0.2)
    ),
    #000000;
}

.lockBorder {
  margin-top: 5px;
  border: 6px solid #e71383;
  padding: 1.2rem;
  border-radius: 100%;
}

.navMenu {
  background: rgba(231, 19, 131, 0.2);
  border: 1px solid #e71383;
  padding: 4px;
  @media (max-width: 768px) {
    padding: 0px;
    width: 100%;
    background: transparent;
    border: none;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(231, 19, 131, 0.2);
      border: 1px solid #e71383;
      margin: 0 8px !important;
      padding: 0 !important;

      a {
        margin: 0;
        font-size: 11px !important;
      }
    }
  }
}

.navLink {
  background: transparent !important;
  margin-left: 0 !important;
  border: 0 !important;
}

.mobileInfo {
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.mobileGrid {
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.mobFlexEnd {
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
}

.gridItem {
  @media (max-width: 758px) {
    width: 45%;
    margin: 0 2%;
    margin-bottom: 16px;
  }
}

.actionLocked {
  background: #460626 !important;
  border: 1px solid #5e0835 !important;
}

.arrowFilter {
  margin-left: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  bottom: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  bottom: -2px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.react-calendar {
  color: black;
}

.datePlaceholder {
  font-weight: 400;
  color: #333;
  font-size: 15px;
  background: #fff;
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid #dddddd;
  width: 50%;
  margin: 8px 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input::placeholder {
  color: #a9a7bb !important;
}

.filterPlaceholder {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  position: relative;
  cursor: pointer;
  padding: 4px 8px;
  margin-left: auto;
  border: 1px solid;
  background: rgba(231, 19, 131, 0.2);
  border: 1px solid #e71383;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterLabel {
  padding: 4px 8px;
  background: #460626;
  width: 100%;
  display: flex;
  align-items: center;

  .arrowFilter {
    margin-left: auto;
  }
}

.react-calendar__tile--rangeStart {
  background: rgba(231, 19, 131, 0.6) !important;
}

.react-calendar__tile--hover {
  background: rgba(231, 19, 131, 0.6) !important;
}

.react-calendar__tile--hoverEnd {
  background: #e71383 !important;
}

input[type="checkbox"]:checked {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 13px;
    height: 15px;
    background: #e71383;
  }
}

.filterContent {
  padding: 4px 8px;
  width: 350px;

  &__row {
    display: flex;
  }
}

.mobHidden {
  @media (max-width: 768px) {
    display: none;
  }
}

.desktopHidden {
  @media (min-width: 768px) {
    display: none;
  }
}

.filterList {
  min-width: 340px;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 100;
  background: #2d041a;
  @apply text-white lg:shadow-lg rounded-lg;
}

.navLink {
  font-family: "Titillium Web";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding: 9px 16px 9px 12px !important;
  border-radius: 18px !important;
  cursor: pointer;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 50;
}

.card-1.hide-bottom-border {
  border-bottom: 0px solid !important;
}

.card-1rewards.hide-bottom-border {
  border-bottom: 0px solid !important;
}

.ReactModal__Content {
  background: #460626 !important;
  border: 1px solid #5e0835 !important;
  border-radius: 0 !important;
  margin: auto;
  z-index: 150;
  display: flex;
  flex-direction: column;
  overflow: inherit !important;

  .closeModalBtn {
    width: 27px;
    height: 27px;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 100;
    cursor: pointer;
  }

  .modal-title {
    font-weight: 600;
    font-size: 24px;
    color: #f0ebff;
    display: flex;
    justify-content: center;

    img {
      margin-right: 12px;
    }
  }

  .rewardsList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &.single {
      justify-content: center !important;
    }
  }

  .popupNavBtn {
    background: #e71383 !important;
    width: 100% !important;
    padding: 12px !important;
    border-radius: 0 !important;
    margin-top: auto !important;
  }

  .telemetryCard {
    margin-top: 36px;
    font-weight: 600;
    color: #f0ebff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    .rewardImgWrapper {
      position: relative;
    }

    .rewardQuantity {
      background: linear-gradient(88.04deg, #2966f5 0%, #f514b2 100%);
      padding: 2px 6px 1px;
      font-weight: 600;
      font-size: 12px;
      color: #f0ebff;
      position: absolute;
      bottom: -5px;
      right: 5px;
    }

    &.single {
      flex-direction: column;
      font-size: 18px;
      margin-right: 0 !important;

      div {
        margin-top: 13px;
      }

      img {
        width: 84px;
        height: 84px;
        border-radius: 0;
        border: 1px solid #f514b2;
      }
    }

    img {
      width: 46px;
      height: 46px;
      border-radius: 0;
      border: 1px solid #f514b2;
      margin-right: 13px;
    }
  }
}

@mixin gradientAnimation($start, $end, $transTime) {
  background-size: 100%;
  background-image: linear-gradient(to right, $start, $end);
  position: relative;
  z-index: 100;

  &:before {
    background-image: linear-gradient(to right, $end, $start);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    transition: opacity $transTime;
    border-radius: 8px;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

// @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
//   .blurred-container {
//     -webkit-backdrop-filter: blur(12px);
//     backdrop-filter: blur(12px);
//   }
// }

// /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
// @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
//   .blurred-container {
//     --tw-bg-opacity: 1;
//     background-color: rgb(2 2 2 / var(--tw-bg-opacity));
//   }
// }

* {
  font-family: "Titillium Web", sans-serif;
  // transition: all 0.2s ease-in-out;
}

body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 4px;
  height: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid #e71383;
}

::-webkit-scrollbar-thumb {
  background: #e71383;
}

svg {
  min-width: 1rem;
}

.lockBorder {
  border: 1px solid #e71383 !important;
}

.gradient-5 {
  background: #230313 !important;
}

.gradient-1 {
  background: linear-gradient(88.04deg, #e71383 0%, #e71383 100%);
  // @apply bg-gradient-to-r from-design-gradientPink to-design-gradientPurple;
}

.tableBg {
  background: rgba(231, 19, 131, 0.2);
}

.transparentWrap {
  background: transparent !important;
}

.tagBg {
  background: #460626 !important;
  border: 1px solid #5e0835 !important;
  border-radius: 0 !important;
}

.bgTransparent {
  background: #460626 !important;
  border: 1px solid #5e0835 !important;
  border-radius: 0 !important;

  &.ingame-info {
    color: var(--grey-grey-10, #f0ebff);
    font-size: 14px;

    a {
      color: #2966f5;
      text-decoration: underline;
    }
  }
}

.pledgePoolOsm {
  background: #460626 !important;
  border: 1px solid #5e0835 !important;
  border-radius: 0 !important;

  @media screen and (min-width: 428px) {
    display: flex;
    > div {
      flex: 1;
    }
  }
}

.borderPink {
  border: 1px solid #5e0835 !important;
  border-radius: 0 !important;
}

.gradient-orange {
  @apply bg-gradient-to-r from-design-gradientOrange to-design-gradientDarkOrange;
}

.gradient-2 {
  @apply bg-gradient-to-r from-design-gradientBlue to-design-gradientDarkBlue;
}

.gradient-3 {
  @apply bg-gradient-to-r from-design-gradientPink to-design-gradientDarkPink;
}

.gradient-4 {
  @apply bg-gradient-to-r from-design-gradientPurple to-design-gradientPink;
}

.clip-text {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-1 {
  @apply text-center font-semibold border border-design-background5 text-design-grey bg-transparent rounded-lg flex items-center justify-center gap-2 px-3 py-1;
  img {
    @apply h-5 w-5 object-contain object-center;
  }
}

.button-2 {
  @apply text-center border border-design-pink text-white bg-design-background2 rounded-lg flex items-center justify-center gap-2 px-3 py-1;
  img {
    @apply h-5 w-5 object-contain object-center;
  }
}

.button-3 {
  background: #e71383;
  border: 1px solid #e71383;

  @apply text-center text-white flex items-center justify-center gap-2 px-3 py-2.5 w-full;
  img {
    @apply h-5 w-5 object-contain object-center;
  }
}

.button-pools {
  background: transparent !important;
  @apply min-w-fit text-center text-white whitespace-nowrap bg-design-background2 flex items-center justify-center gap-2 font-semibold px-4 py-2 lg:py-1.5;
  img {
    @apply h-5 w-5 object-contain object-center;
  }
}

.button-pools-active {
  background: #e71383 !important;
  @apply min-w-fit text-center text-white bg-design-blue flex items-center justify-center gap-2 font-semibold px-4 py-2 lg:py-1.5;
  img {
    @apply h-5 w-5 object-contain object-center;
  }
}

.rounded-button-1 {
  @apply p-1.5 w-8 lg:w-7 rounded-full overflow-hidden aspect-square flex items-center justify-center;
  svg {
    @apply w-full h-full object-contain object-center;
  }
}

.link-active {
  @apply text-design-white;
}

.link-active-2 {
  @apply text-design-white border-b-2 border-design-pink text-design-pink;
}

.navLink.selected {
  background: #e71383 !important;
  border-radius: 0 !important;
}

.card-1 {
  background: rgba(231, 19, 131, 0.2);
  border: 1px solid #e71383 !important;
  backdrop-filter: blur(10px);
  @apply relative text-white lg:shadow-lg p-5;
}

.card-1rewards {
  border: 1px solid #e71383 !important;
  @apply relative bg-design-background2 text-white border border-design-darkBlue p-5;
}

.card-pagination {
  border: 0;
  padding-left: 16px;
  @apply bg-design-background2 text-white;
}

.tableHeader {
  @apply relative;
  padding-top: 10px;
  backdrop-filter: blur(10px);

  &::before {
    content: "";
    width: 100%;
    height: 20px;
    position: absolute;
    top: -20px;
    backdrop-filter: blur(10px);
  }
}

.estimatedRew {
  background: #460626;
  border: 0;
}

.ovverideBgAndBorder {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    #2f041a !important;
  border: 1px solid #5e0835 !important;
  border-radius: 0 !important;
}

.bg-design-background5 {
  background: #460626 !important;
}

.card-2 {
  @apply relative bg-design-background5 text-white border border-design-steelBlue shadow rounded-xl p-4;
}

.card-3 {
  @apply relative bg-design-background5 text-white border border-design-blue shadow rounded-xl p-4;
}

.card-heading-1 {
  @apply text-2xl font-semibold;
}

.card-heading-3 {
  @apply pb-1 mb-3 flex items-center gap-1.5;
  img {
    @apply h-4 w-4 object-contain object-center;
  }
}

.card-heading-4 {
  border-color: #5e0835 !important;
  @apply text-base text-design-grey lg:pb-1 lg:border-b border-design-darkBlue lg:mb-1 flex items-center gap-1.5;
  img {
    @apply h-4 w-4 object-contain object-center;
  }
}

tr {
  border-color: #5e0835 !important;
}

.flex-0-5 {
  flex: 0.5;
}

.balance-item {
  border-color: #5e0835 !important;
  @apply grid grid-cols-2 gap-1 lg:block border-b lg:border-0 pb-1.5 lg:pb-0;
}

.balance-1 {
  @apply flex items-center gap-2;
  img {
    @apply h-4 w-4 object-contain object-center;
  }
}

.external-link {
  @apply text-design-blue inline-flex items-center gap-1;
}

.section-heading-1 {
  @apply text-3xl font-bold flex items-center gap-4;
  img {
    @apply h-6 w-6 object-contain object-center;
  }
}

.tag-1 {
  @apply text-xs border border-design-darkBlue2 bg-design-background2 flex items-center justify-center gap-1 px-3 py-1 rounded-md;
  img {
    @apply h-4 w-4 object-contain object-center;
  }
}

.tag-2 {
  @apply text-xs border border-design-darkBlue2 bg-design-background2 flex items-center justify-center gap-1 px-3 py-1 rounded-full;
  img {
    @apply h-4 w-4 object-contain object-center;
  }
}

.tag-3 {
  @apply text-design-pink bg-design-backgroundPink text-xs flex items-center justify-center gap-1 px-2 py-0.5 rounded-full w-fit;
  img {
    @apply h-4 w-4 object-contain object-center;
  }
}

.card-icon-1 {
  @apply w-12 h-12 border border-design-darkBlue2 bg-design-background flex items-center justify-center p-2 rounded-md;
  min-width: 3rem;
  img {
    @apply h-full w-full object-contain object-center;
  }
}

.card-icon-2 {
  @apply w-12 h-12 border border-design-darkBlue2 bg-design-background flex items-center justify-center rounded-md;
  min-width: 3rem;
  img {
    @apply h-full w-full object-cover object-center;
  }
}

input,
select {
  @apply w-full h-full bg-design-darkBlue text-white rounded-lg px-4 py-3 outline-none focus:ring-opacity-80;
}

input:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: #e71383;
}

select {
  @apply appearance-none cursor-pointer;
}

.select {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    #2f041a;
  border: 1px solid #5e0835;
  border-radius: 0 !important;
  @apply relative cursor-pointer;

  select {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
      #2f041a;
    border-radius: 0 !important;

    &:focus {
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
        var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
        var(--tw-shadow, 0 0 #0000);
      --tw-ring-color: #e71383;
    }
  }

  div {
    @apply absolute top-1/2 right-2 transform -translate-y-1/2 text-design-grey;
    svg {
      @apply h-4 w-4 object-contain object-center;
    }
  }
}

.select-alt {
  select {
    @apply text-sm py-2 px-4 bg-transparent border-2  border-design-background5;
    min-width: 100px;
  }
}

.counter {
  @apply w-full relative;
  padding: 0 1px;
  input {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
      #2f041a !important;
    border: 1px solid #5e0835;
    border-radius: 0 !important;
    @apply w-full h-full bg-design-darkBlue text-white px-4 py-3 placeholder:text-design-darkBlue2;
  }
  div {
    @apply absolute h-4/5 top-1/2 right-2 text-design-grey overflow-hidden transform -translate-y-1/2  flex flex-col items-center justify-center;
    button {
      background: transparent !important;
      @apply flex-1 aspect-square bg-design-background3 hover:bg-design-darkBlue2 flex items-center justify-center p-0.5;
    }
  }
}

.pinkInput {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    #2f041a;
  border: 1px solid #5e0835;
  border-radius: 0;
  color: #a9a7bb;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

th {
  @apply font-semibold;
}

tr {
  @apply py-1 border-b border-design-darkBlue last:border-b-0;
}

.rewards-table {
  tr {
    @apply grid grid-cols-3 gap-1;
  }
}

.vesting-table {
  thead {
    tr {
      @apply p-0 pb-2;
    }
  }
  th {
    @apply text-design-darkBlue2;
  }
  tr {
    @apply grid grid-cols-7 gap-1;
    img {
      @apply h-4 w-4 object-contain object-center;
    }
  }
  td {
    @apply flex items-center gap-2;
  }
  button {
    max-width: 144px;
    @apply text-sm;
  }
}

.text-design-darkBlue2 {
  color: #a9a7bb !important;
}

.telemetry-rewards-table {
  thead {
    tr {
      @apply p-0 pb-2;
    }
  }
  th {
    @apply text-design-darkBlue2;
  }
  tr {
    @apply grid grid-cols-8 gap-1;
    img {
      @apply h-4 w-4 object-contain object-center;
    }
  }
  td {
    @apply flex items-center gap-2;
  }
  button {
    @apply text-sm;
  }
}

.progress-wrapper {
  @apply relative;
  svg {
    @apply block relative w-8 h-8 transform -rotate-90 stroke-design-pink fill-transparent overflow-visible;
    // stroke-dasharray: 0, 100;
    stroke-dashoffset: 0;
    stroke-width: 4px;
    z-index: 2;
  }
  &::after {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2   border-4 border-design-backgroundPink rounded-full;
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: 1;
  }
  p {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white text-xs font-semibold;
  }
}

.empty-vesting {
  height: 400px;
  padding-top: 0;
  border: 0;
  // overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.mobTableHeight {
  @media (max-width: 767px) {
    height: 350px !important;
    margin-top: 10px;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
